<template>
    <div id="content" class="modules">
        <div class="bar-actions">
            <Button bType="cancel_changes" :bCallback="goBack" customClass="btn-spaced"></Button>
            <Button
                bType="save"
                :bCallback="save"
                :bLabel="'user.form.save'"
                :customClass="{
                    'btn-action': true
                }"
            ></Button>
        </div>
        <div class="modules-box">
            <div class="form">
                <div class="title-tools">{{ $t('modules.tools') }}</div>
                <div class="title-desc">{{ $t('modules.description') }}</div>
                <div class="tools-container" v-if="titles.length > 0">
                    <div class="switches" v-for="(tool, index) in titles" :key="index">
                        <div class="switch">
                            <div class="switch-click" @click="check(tool)">
                                <input class="switch-input-form" :checked="hasTool(tool.key)" type="checkbox" />
                            </div>
                            <h2 class="label-form">{{ tool.title }}</h2>
                        </div>
                        <div class="switch"></div>
                        <div class="switch-click" v-if="tool.key === 5">
                            <div class="subTool">
                                <input
                                    class="switch-input-form"
                                    @click="checkAndyContent(user.config.andyContentAvailable)"
                                    :checked="switchActive(user.config.andyContentAvailable)"
                                    type="checkbox"
                                />
                                <h2 class="label-form" v-html="$t('modules.library.tool1')"></h2>
                            </div>
                            <div class="subTool">
                                <input
                                    class="switch-input-form"
                                    @click="checkFoodSafety(user.config.foodSafetyAvailable)"
                                    :checked="switchActive(user.config.foodSafetyAvailable)"
                                    type="checkbox"
                                />
                                <h2 class="label-form" v-html="$t('modules.library.tool2')"></h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="main-tool">
                    <p>{{ $t('modules.mainTool.title') }}</p>
                    <!-- FUNCIONA PERO ES SIN VUETIFY -->
                    <!-- <select v-model="mainTool" class="select">
                            <option v-for="tool in toolsSelected" :key="tool.key" :value="tool.title">{{tool.title}}</option>
                        </select> -->

                    <v-select
                        hide-details
                        class="select"
                        v-model="mainTool"
                        :items="Object.values(toolsSelected)"
                        item-text="title"
                        item-value="title"
                        dense
                        :no-data-text="$t('supervise.filter.no_results')"
                        :placeholder="$t('assets.filter.filter_by')"
                    ></v-select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { TOOLS } from '@/constants'
import i18n from '@/i18n'

export default {
    name: 'Modules',
    data() {
        return {
            currentPage: 'modules',
            toolSwitch: [],
            titles: [
                { key: TOOLS.CHECKLISTS, title: i18n.t('module.1') },
                { key: TOOLS.REGISTERS, title: i18n.t('module.4') },
                { key: TOOLS.LABELS, title: i18n.t('module.3') },
                { key: TOOLS.DIGITAL_LABEL, title: i18n.t('module.11') },
                { key: TOOLS.ISSUES, title: i18n.t('module.2') },
                { key: TOOLS.AUDITS, title: i18n.t('module.7') },
                { key: TOOLS.LIBRARY, title: i18n.t('module.5') },
                { key: TOOLS.LOGBOOK, title: i18n.t('module.10') }
            ],
            mainTool: null,
            toolsPath: {
                '/checklist': 1,
                '/register': 2,
                '/label': 3,
                '/issues': 4,
                '/library': 5,
                '/audit': 7,
                '/chat': 10
            }
        }
    },
    computed: {
        user() {
            return this.$store.getters['login/getLocalEmployee']
        },
        tools() {
            var visible = []
            if (localStorage.config) {
                var config = JSON.parse(localStorage.config)
                if (config.tools) {
                    visible = config.tools
                }
            }
            return visible
        },
        toolsSelected() {
            const toolsFiltered = this.titles.filter((tool) => this.toolSwitch.includes(tool.key))
            return toolsFiltered
        }
    },
    methods: {
        check(tool) {
            if (this.toolSwitch.indexOf(tool.key) > -1) {
                this.toolSwitch.splice(this.toolSwitch.indexOf(tool.key), 1)
                if (tool.title === this.mainTool) {
                    this.mainTool = this.toolsSelected.length > 0 ? this.toolsSelected[0].title : null
                }
                if (tool.key === 5) {
                    this.user.config.andyContentAvailable = 0
                    this.user.config.foodSafetyAvailable = 0
                }
            } else {
                this.toolSwitch.push(tool.key)
            }
            this.$forceUpdate()
        },
        checkAndyContent(tool) {
            if (tool === 1) {
                this.user.config.andyContentAvailable = 0
            } else {
                this.user.config.andyContentAvailable = 1
            }
            this.$forceUpdate()
        },
        checkFoodSafety(tool) {
            if (tool === 1) {
                this.user.config.foodSafetyAvailable = 0
            } else {
                this.user.config.foodSafetyAvailable = 1
            }
            this.$forceUpdate()
        },
        hasTool(tool) {
            return this.toolSwitch.indexOf(tool) > -1
        },
        switchActive(toolName) {
            return this.toolSwitch.indexOf(5) > -1 && toolName === 1
        },
        save() {
            var self = this
            // sort tools by key asc
            var tools = this.toolSwitch
                .sort(function (a, b) {
                    return a - b
                })
                .toString()
            const params = {
                tools: tools,
                foodSafetyAvailable: this.user.config.foodSafetyAvailable,
                andyContentAvailable: this.user.config.andyContentAvailable
            }

            if (this.mainTool) {
                params.home = this.titles.find(({ title }) => title === this.mainTool).key
            }

            this.$store.dispatch('account/updateModules', params).then(function (response) {
                if (response) {
                    self.$snackbar.open({
                        message: self.$t('snackbar.save_success'),
                        customClass: 'success',
                        duration: 3000
                    })
                    setTimeout(() => {
                        location.reload()
                    }, 2000)
                }
            })
        },
        goBack() {
            window.history.back()
        }
    },
    mounted() {
        if (Object.values(this.tools).length > 0) {
            this.toolSwitch = this.tools
        }
        const localHome = localStorage.config ? JSON.parse(localStorage.config).home : null

        if (localHome) {
            this.mainTool = this.titles.find((tool) => tool.key === this.toolsPath[localHome]).title
        } else {
            this.mainTool = this.toolsSelected[0].title
        }
    }
}
</script>

<style lang="scss">
#content.modules {
    padding-left: 1em;
    padding-right: 1em;

    .bar-actions {
        .btn-cancel_changes {
            margin-right: 0;
        }
    }
    .modules-box {
        @include display-flex();
        @include flex-direction(column);
        align-items: center;
        @include box-shadow($h: 0px, $v: 0px, $b: 12px, $c: rgba($color-neutral-600, 0.1));
        @include border-radius(3px);
        overflow: hidden;
        // width: 80%;
        width: 100%;
        background-color: $color-neutral-50;
        height: calc(100% - (63px + 23px));
        display: flex;
        margin: 0 auto;
        margin-top: 76px;
        padding: 4em 6em;

        .form {
            width: 800px;
        }

        .title-tools {
            font-family: $text-bold;
            @include font-size(lg);
            color: $color-black;
            padding-bottom: 6px;
        }
        .title-desc {
            font-family: $text;
            @include font-size(sm);
            color: $color-black;
            padding-bottom: 60px;
        }
        .tools-container {
            @include display-flex();
            @include flex-direction(column);
            gap: 10px;

            .switches {
                .switch-click {
                    padding-right: 12px;
                }
                .subTool {
                    display: flex;
                    padding-left: 45px;
                    margin-top: 10px;
                    .switch-input-form {
                        margin-right: 12px;
                    }
                }
            }
        }

        .main-tool {
            margin-top: 24px;
            width: 50%;
        }
    }
}
</style>
